import '../styles/com.scss';

import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect,useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import StateInsurance from '../components/state-insurance';

const caMale = 'https://cdn.getmybubble.com/images/corporate-sites/ca-male.png';
const caFemale = 'https://cdn.getmybubble.com/images/corporate-sites/ca-female.png';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = [];
  for ( let i = 0; i < data.length; i++ ) {
    if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
      uniqueSectionArray.push( data[i].node.data_category );
    }

  }
  return uniqueSectionArray;
};

let getformattedData2 = ( data, sectionsArray ) => {
  let output = {};
  let section;
  let column;
  sectionsArray.forEach( sectionValue => {
    output[sectionValue] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionValue && item.node );
    for ( let col = 1; col < 5; col++ ) {
      output[sectionValue]['column_' + col] = {};
      column = section.filter( ( item ) => item.node.column == col && item.node );
      let len = column.length;
      for ( let j = 0; j < len; j++ ) {
        output[sectionValue]['column_' + col][column[j].node.style_category] = column[j].node;
      }


    }
  } );

  return output;
};

function CaliforniaInsurance() {


  const [formattedData, setFormattedData] = useState();

  const data = useStaticQuery(
    graphql`
    query {
      allStrapiPageData(filter: {page_name: {eq: "life_insurance_landing_page"}}) {
        edges {
          node {
            data_category
            column
            content
            style_category
            attribute_1_value
            attribute_2_value
            attribute_1_key
            attribute_2_key
          }
        }
      }
    }
    `
  );

  useEffect( () => {
    let pageData = data.allStrapiPageData.edges;
    let uniqueSectionData = getUniqueSectionData( pageData );
    const fmtData2 = getformattedData2( pageData, uniqueSectionData );
    setFormattedData( fmtData2 );

  }, [data] );

  return(
    <Layout>
      <SEO
        keywords="life insurance california, best life insurance in california, life, insurance quotes california, best term life insurance in california, term life insurance california, life insurance quotes in california"
        title='Get Life Insurance Quotes and Policies in California on Low Premium'
        description="Get Life Insurance quotes in California in minutes. Bubble helps you choose the best suited life insurance policies &amp; plans in California on low premium while you're young and while locking in your health score."/>

      <div className="top-section">

      </div>

      <div className="row">
        <div className="col-lg-6 col-12">
          <img src={caFemale} alt="Female Coverages" />
        </div>

        <div className="col-lg-6 col-12">
          <img src={caMale} alt="Male Coverages" />
        </div>
      </div>

      <div className="row orange-bg" >
        <div className="col-lg-2"></div>

        <div className="col-lg-8 d-md-12">
          <div className="flex-column d-flex">
            <h1 className='text-center'>
          Life Insurance California
            </h1>

            <p>
          Life insurance is a financial buffer you help your loved ones with to protect them financially when you're no longer there. Life insurance in California provides a tax-free lump sum of money, called the death benefit, to a designated beneficiary after your death. You get to pick the life insurance policy that's right for you by checking the life insurance quote. When you buy a life insurance policy when you're young and healthy, your life insurance premium is most likely to be on the lower side. You can also buy life insurance for kids or even your parents. If you’re in California, get your life insurance quote online with Bubble and protect everyone you love in minutes.
            </p>
          </div>
        </div>

      </div>

      <div className="container">
        <div className="row info-para">
          <div className="col-lg-2"></div>

          <div className="col-lg-8 d-md-12">
            <div className="flex-column d-flex">
              <h2 className='text-center'>
              Your Life Insurance premium is cheaper when you’re young
              </h2>

              <p>
              Here’s a break up of what life insurance in California would be like for you. A life insurance policy in California for a young person in the age group of 20 to 30 works out a lot cheaper than for someone in the age group of 40 to 50. When you’re younger your life insurance premium is lower and with Bubble, it can be locked for decades.
              </p>
            </div>
          </div>

        </div>
      </div>

      <StateInsurance/>
    </Layout>
  );
}

export default CaliforniaInsurance;
